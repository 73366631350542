export const registerCarousel = [
    {
      id:1,
      title:"<span class='black-text'>Bonjour et bienvenue </br> dans ta formation </br></span><span class='blue-text'>Orthographe Plus </span><span class='black-text'>!</span>",
      subtitle:"",
      icon:"./../../../../assets/images/icons/svg-images/register-page1.svg",
    },
    {
      id:2,
      title:"<span class='black-text'>La formation en </br>orthographe entièrement </br></span><span class='blue-text'>sur-mesure </span><span class='black-text'>...</span>",
      subtitle:"",
      icon:"./../../../../assets/images/icons/svg-images/register-page2.svg",
    },
    {
      id:3,
      title:"<span class='black-text'>Réalisable en </br></span><span class='blue-text'>10 minutes par jour </span><span class='black-text'>...</span>",
      subtitle:"",
      icon:"./../../../../assets/images/icons/svg-images/register-page3.svg",
    },
    {
      id:4,
      title:"<span class='black-text'>Et qui arrive </span><span class='blue-text'>chaque matin </br></span><span class='black-text'>dans ta </span><span class='blue-text'>boîte mail </span><span class='black-text'>!</span>",
      subtitle:"",
      icon:"./../../../../assets/images/icons/svg-images/register-page4.svg",
    },
    {
      id:5,
      title:"<span class='black-text'>Pour commencer,</br> nous aimerions que tu </span><span class='blue-text'>répondes </span><span class='black-text'>à </br></span><span class='blue-text'>quelques questions </span><span class='black-text'>!</span>",
      subtitle:"",
      icon:"./../../../../assets/images/icons/svg-images/register-page5.svg",
    },
    {
      id:6,
      title:"<span class='black-text'>Quel est ton </span><span class='blue-text'>genre </span><span class='black-text'>?</span>",
      subtitle:"Je suis...",
      icon:"",
  
    },
    {
      id:7,
      title:"<span class='black-text'>Quel est ton </span><span class='blue-text'>prénom </span><span class='black-text'>?</span>",
      subtitle:"Je m'appelle...",
      icon:"",
  
    },
    {
      id:8,
      title:"<span class='black-text'>Quel est ton </span><span class='blue-text'>nom </span><span class='black-text'>?</span>",
      subtitle:"Mon nom est...",
      icon:"",
  
    },
    {
      id:9,
      title:"<span class='black-text'>Quel est ton </span><span class='blue-text'>date de naissance </span><span class='black-text'>?</span>",
      subtitle:"Je suis né(e) le...",
      icon:"",
  
    },
    {
      id:10,
      title:"<span class='blue-text'>Pourquoi </span><span class='black-text'>souhaites-tu </br>te former en </span><span class='blue-text'>orthographe </span><span class='black-text'>?</span>",
      subtitle:"",
      icon:"",
  
    },
    {
      id:11,
      title:"<span class='black-text'>Choisis un </span><span class='blue-text'>mot de passe </span><span class='black-text'>...</span>",
      subtitle:"pour te connecter à ton espace </br> personnel.",
      icon:"",
  
    },
    {
      id:12,
      title:"<span class='black-text'>Quel est ton </span><span class='blue-text'>numéro de téléphone </span><span class='black-text'>?</span>",
      subtitle:"",
      icon:"",
  
    },
    {
      id:13,
      title:"<span class='black-text'>Ajoute une </span><span class='blue-text'>photo </br></span><span class='black-text'>à ton profil pour le </br>personnaliser.</span>",
      subtitle:"Ta photo ne sera visible que par toi et les </br> personnes avec qui tu joues en ligne.",
      icon:"",
    },
    {
      id:14,
      title:"<span class='blue-text'>Vérifie </span><span class='black-text'>tes informations </br>personnelles.</span>",
      subtitle:"",
      icon:"",
    },
    {
      id:15,
      title:"<span class='blue-text'>Merci </span><span class='black-text'>pour tes réponses !</span>",
      subtitle:"Commence ta formation avec un petit test de niveau qui te permettra</br> de <span class='blue-text'> personnaliser </span>ton parcours.",
      icon:"",
    },
   ]

export const imageOptions = [
    {
      title:"",
      subtitle:"",
      icon:'./../../../../assets/images/icons/svg-images/register-page-13-2.svg'
    },
    {
      title:"",
      subtitle:"",
      icon:'./../../../../assets/images/icons/svg-images/register-page-13-3.svg'
    },
    {
      title:"",
      subtitle:"",
      icon:'./../../../../assets/images/icons/svg-images/register-page-13-4.svg'
    },
    {
      title:"",
      subtitle:"",
      icon:'./../../../../assets/images/icons/svg-images/register-page-13-5.svg'
    },
]

export const reasonOptions = [
    {
      title:"Développement personnel",
      subtitle:"Ma satisfaction personnelle.",
      icon:'./../../../../assets/images/icons/svg-images/register-page-10-1.svg'
    },
    {
      title:"Carrière",
      subtitle:"Éviter les fautes d'orthographe au travail.",
      icon:'./../../../../assets/images/icons/svg-images/register-page-10-2.svg'
    },
    {
      title:"École",
      subtitle:"Je ne veux pas rendre des copies contenant des fautes.",
      icon:'./../../../../assets/images/icons/svg-images/register-page-10-3.svg'
    },
    {
      title:"Autre",
      subtitle:"Divers raisons.",
      icon:'./../../../../assets/images/icons/svg-images/register-page-10-4.svg'
    },
]

export const genderOptions =[
    {
      name:'Femme',
      value:'Femme'
    },
    {
      name:'Homme',
      value:'Homme'
    },
   ]