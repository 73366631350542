import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sms-component',
  templateUrl: './sms-component.component.html',
  styleUrls: ['./sms-component.component.scss']
})
export class SmsComponentComponent implements OnInit {
  questionInfo
  constructor() { }

  ngOnInit(): void {
    this.questionInfo = {
      conversation:[
        {
          "text": "Tu sais qui a été invité pour la conférence de demain ?",
          "isLeft": false,
          "_id": "64e16226fa7295458ff83465",
          "id": "64e16226fa7295458ff83465"
        },
        {
          "text": "Oui ! La  société Invest.",
          "isLeft": true,
          "_id": "64e16226fa7295458ff83466",
          "id": "64e16226fa7295458ff83466"
        },
        {
          "text": "Quelle est l'activité de cette société ?",
          "isLeft": false,
          "_id": "64e16226fa7295458ff83467",
          "id": "64e16226fa7295458ff83467"
        },
        {
          "text": "C'est un nouveau_____d'investissement basé à Londres.",
          "isLeft": true,
          "_id": "64e16226fa7295458ff83468",
          "id": "64e16226fa7295458ff83468"
        }
      ]
    }
  }

}
