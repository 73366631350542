import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
const basePath = environment.basePath;

@Injectable({
  providedIn: "root",
})
export class AnecdoteService {
    constructor(private http: HttpClient) {}
    getrandomAnecdote() {
      return this.http.get(`${basePath}/anecdotes/random`);
    }
}