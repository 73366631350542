import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'true-false-component',
  templateUrl: './true-false-component.component.html',
  styleUrls: ['./true-false-component.component.scss']
})
export class TrueFalseComponentComponent implements OnInit {
  @Input() item: any;
  constructor() { }

  ngOnInit(): void {}

  getTFBackgroundColor(item: any, isTrue: boolean): string {
    if(this.item.isCorrect){
      if(this.item.answer == isTrue) return "green-background";
    }else{
      if(this.item.answer == isTrue) return "red-background";
      else return "green-background"
    }
  }
}
