export const locale = {
  lang: 'fr',
  data: {
    MENU: {
      HOME: 'Apprentissage',
      RULE: 'Règles',
      GAME: 'Jeu',
      CHAT: 'Chat',
    },
  }
}
