<div class="col-12 mb-4 center-elements">
    <div class="mb-4">
      <span class="boxes" [ngClass]="getTFBackgroundColor(item, true)"
        >Vrai</span
      >
      <span
        class="boxes"
        [ngClass]="getTFBackgroundColor(item, false)"
        >Faux</span
      >
    </div>
    <div
      style="text-align: center"
      *ngIf="item.responseStatus == 'notSetted'"
    >
      <div>
        <span
          class="boxes"
          style="background-color: '#76b6f0'; color: 'white'"
          >Je ne sais pas</span
        >
      </div>
    </div>
  </div>