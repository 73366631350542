<!-- <div class="auth-wrapper auth-v2">
    <div class="auth-inner row m-0">
      <a class="brand-logo" href="javascript:void(0);">
        <h2 class="brand-text text-primary ml-1"></h2></a
      >

    </div>
  </div> -->

<div class="row">
  <div class="col-8">
    <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="70" />
  </div>
  <div class="col-4 pt-2 pr-3" style="text-align: right">
    <button pButton type="button" label="login" class="p-button-text"></button>
  </div>
  <div class="col-lg-12 mt-5 pl-5 pr-5" style="z-index: 200">
    <p-carousel
      [value]="formValue"
      [numVisible]="1"
      [numScroll]="1"
      [responsiveOptions]="responsiveOptions"
      [page]="0"
    >
      <ng-template let-formValue pTemplate="item">
        <form
          [formGroup]="registerForm"
          class="source-item"
          style="width: 100%"
        >
          <div class="container-fluid callback">
            <div class="container pt-3 pb-2">
              <div class="row">
                <div
                  [ngClass]="{
                    'col-6': formValue.id == 13,
                    'col-12': formValue.id !== 13
                  }"
                  class="pb-1"
                >
                  <h1>
                    <b>
                      <span [innerHTML]="formValue?.title"></span>
                    </b>
                  </h1>
                  <div
                    *ngIf="formValue?.subtitle && formValue?.subtitle !== ''"
                  >
                    <h2>
                      <span [innerHTML]="formValue?.subtitle"></span>
                    </h2>
                  </div>
                </div>
                <div class="col-6" *ngIf="formValue.id == 13">
                  <img
                    class="img-fluid"
                    src="./../../../../assets/images/icons/svg-images/register-page-13-1.svg"
                    style="width: 262px; float: right"
                    alt="icon"
                  />
                </div>
                <div
                  class="col-12 center-with-padding"
                  style="top: 170px"
                  *ngIf="formValue?.id == 6"
                >
                  <p-dropdown
                    options="genreOptions"
                    optionLabel="label"
                    [autoDisplayFirst]="false"
                    optionValue="value"
                    formControlName="gender"
                    id="kind"
                    placeholder="Mon genre"
                  >
                  </p-dropdown>
                </div>
                <div
                  class="col-12 center-with-padding"
                  style="top: 170px"
                  *ngIf="formValue?.id == 7"
                >
                  <input
                    type="text"
                    pInputText
                    formControlName="lastName"
                    id="title"
                    placeholder="Prénom"
                  />
                </div>
                <div
                  class="col-12 center-with-padding"
                  style="top: 170px"
                  *ngIf="formValue?.id == 8"
                >
                  <input
                    type="text"
                    pInputText
                    formControlName="firstName"
                    id="title"
                    placeholder="Nom"
                  />
                </div>
                <div
                  class="col-12 row center-with-padding"
                  style="top: 170px"
                  *ngIf="formValue?.id == 9"
                >
                  <div class="col-4">
                    <p-dropdown
                      options="[]"
                      optionLabel="label"
                      [autoDisplayFirst]="false"
                      optionValue="value"
                      formControlName="day"
                      placeholder="jj"
                    >
                    </p-dropdown>
                  </div>
                  <div class="col-4">
                    <p-dropdown
                      options="[]"
                      optionLabel="label"
                      [autoDisplayFirst]="false"
                      optionValue="value"
                      formControlName="month"
                      placeholder="MM"
                    >
                    </p-dropdown>
                  </div>
                  <div class="col-4">
                    <p-dropdown
                      options="[]"
                      optionLabel="label"
                      [autoDisplayFirst]="false"
                      optionValue="value"
                      formControlName="year"
                      placeholder="AAAA"
                    >
                    </p-dropdown>
                  </div>
                </div>
                <div
                  class="col-12 row reason-class"
                  *ngIf="formValue?.id == 10"
                >
                  <p-selectButton
                    [options]="reasonOptions"
                    formControlName="reason"
                    optionLabel="icon"
                  >
                    <ng-template let-element>
                      <div class="row">
                        <div
                          class="col-8"
                          style="text-align: left; width: 280px"
                        >
                          <h4>{{ element.title }}</h4>
                          <h6>{{ element.subtitle }}</h6>
                        </div>
                        <div class="col-4">
                          <img
                            class="img-fluid"
                            [src]="element?.icon"
                            style="width: 100px"
                            alt="icon"
                          />
                        </div>
                      </div>
                    </ng-template>
                  </p-selectButton>
                </div>
                <div
                  class="col-12 row center-with-padding"
                  style="top: 170px"
                  *ngIf="formValue?.id == 11"
                >
                  <div class="col-12 mb-2">
                    <input
                      type="password"
                      pInputText
                      formControlName="password"
                      id="password"
                      placeholder="Mot de passe"
                    />
                  </div>
                  <div class="col-12">
                    <input
                      type="password"
                      pInputText
                      formControlName="confirmPassword"
                      id="confirmPassword"
                      placeholder="Confirme ton mot de passe"
                    />
                  </div>
                </div>
                <div
                  class="col-12 center-with-padding"
                  *ngIf="formValue?.id == 12"
                  style="top: 195px; display: flex"
                >
                  <p-dropdown
                    [options]="phoneOptions"
                    formControlName="phone"
                    optionLabel="dial_code"
                    [filter]="true"
                    filterBy="name"
                    [showClear]="true"
                    placeholder="Tél"
                  >
                    <ng-template let-country pTemplate="item">
                      <div class="country-item">
                        <img
                          src="assets/images/flagspack/{{
                            country.code.toLowerCase()
                          }}.svg"
                          alt="{{ country.name.toLowerCase() }}"
                          [class]="
                            'flag flag-' +
                            country.code.toLowerCase() +
                            ' imgFlag'
                          "
                        />
                        {{ country.dial_code }}
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <input
                    type="text"
                    pInputText
                    formControlName="phone"
                    id="phone"
                    placeholder="0.00.00.00.00"
                  />
                  <!-- <div class="col-9">
                    
                  </div> -->
                </div>
                <div
                  class="col-12 row"
                  *ngIf="formValue?.id == 13"
                  style="text-align: center"
                >
                  <div class="col-12">
                    <p-selectButton
                      [options]="imageOptions"
                      [formControlName]="image"
                      optionLabel="icon"
                    >
                      <ng-template let-element>
                        <img
                          class="img-fluid"
                          [src]="element?.icon"
                          style="width: 110px"
                          alt="icon"
                        />
                      </ng-template>
                    </p-selectButton>
                  </div>
                  <div class="col-12" style="text-align: center">
                    <h6>… ou choisis un avatar.</h6>
                  </div>
                </div>
                <div
                  class="col-12 center-with-padding row"
                  style="top: 170px"
                  *ngIf="formValue?.id == 14"
                >
                  <div class="col-6 mb-2">
                    <input
                      type="text"
                      pInputText
                      formControlName="firstName"
                      id="title"
                      placeholder="Nom"
                    />
                  </div>
                  <div class="col-6 mb-2">
                    <input
                      type="text"
                      pInputText
                      formControlName="lastName"
                      id="title"
                      placeholder="Prénom"
                    />
                  </div>
                  <div class="col-12 mb-2">
                    <input
                      type="text"
                      pInputText
                      formControlName="phone"
                      id="phone"
                      placeholder="(+33)0.00.00.00.00"
                    />
                  </div>
                  <div class="col-12 mb-2">
                    <input
                      type="password"
                      pInputText
                      formControlName="password"
                      id="password"
                      placeholder="Mot de passe"
                    />
                  </div>
                </div>
                <div
                  class="col-12"
                  style="height: 400px"
                  *ngIf="formValue?.icon && formValue?.icon !== ''"
                >
                  <img
                    class="img-fluid"
                    [src]="formValue?.icon"
                    [alt]="'page' + formValue.id"
                    style="float: right; z-index: 100"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </ng-template>
    </p-carousel>
  </div>
  <div class="col-12 footer-svg" style="z-index: 1">
    <img src="assets\images\icons\background-wave.svg" alt="" />
  </div>
</div>
