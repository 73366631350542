<div
  class="mb-1 row"
  *ngIf="!clicked"
  style="place-content: center"
  [ngClass]="{ info: theme === 'info', danger: theme === 'danger' }"
>
  <div
    class="col-lg-auto col-md-auto col-sm-auto"
    style="
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      padding-top: 1%;
      padding-bottom: 1%;
      box-shadow: 3px 4px 5px rgb(0 0 0 / 13%);
    "
    [ngStyle]="{
      'background-image':
        theme == 'correct'
          ? 'linear-gradient(to bottom right, #11998e, #38ef7d)'
          : theme == 'wrong'
          ? 'linear-gradient(to bottom right, #CB2D3E, #EF473A)'
          : 'none'
    }"
  >
    <span>
      <img
        src="./../../../assets/images/icons/info-check.svg"
        alt="check-icon"
        *ngIf="theme == 'correct'"
      />
      <img
        src="./../../../assets/images/icons/info-cross.svg"
        alt="cross-icon"
        *ngIf="theme == 'wrong'"
      />
    </span>
  </div>
  <div
    class="col-lg-8 col-md-6 col-sm-6"
    style="
      display: flex;
      align-items: center;
      background-color: #eef7fd;
      box-shadow: 3px 4px 5px rgb(0 0 0 / 13%);
    "
  >
    <span [innerHTML]="infoMessage"></span>
  </div>
  <div
    class="col-lg-auto col-md-auto col-sm-auto"
    style="
      border-left: 2px solid #dedede;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      display: flex;
      background-color: #eef7fd;
      box-shadow: 3px 4px 5px rgb(0 0 0 / 13%);
    "
  >
    <button
      pButton
      type="button"
      label="Pourquoi ?"
      class="p-button-text"
      (click)="getDetail()"
    ></button>
  </div>
</div>
<div class="row mb-1" *ngIf="clicked" style="place-content: center">
  <div
    [ngClass]="{
      'gradient-top-success-border': theme === 'correct',
      'gradient-top-wrong-border': theme === 'wrong'
    }"
  >
    <span [innerHTML]="text"></span>
  </div>
</div>
