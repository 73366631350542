import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "info",
  templateUrl: "./info.component.html",
  styleUrls: ["./info.component.scss"],
})
export class InfoComponent implements OnInit {
  @Input() infoMessage!: String;
  @Input() theme?: String;
  @Input() text?: String;
  @Input() clicked?: boolean = false;
  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.cd.detectChanges();
  }
  getDetail() {
    this.clicked = !this.clicked;
  }
}
