import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'correction-component',
  templateUrl: './correction-component.component.html',
  styleUrls: ['./correction-component.component.scss']
})
export class CorrectionComponentComponent implements OnInit {
  ngOnInit(): void {
  }

  @Input() initialText: string = '';
  @Output() textChanged = new EventEmitter<string>();

  editing = false;
  editedText = '';
  hovered = false;

  startEditing() {
    this.editing = true;
    this.editedText = this.initialText;
  }

  finishEditing() {
    this.editing = false;
    this.textChanged.emit(this.editedText);
  }

  showTooltip() {
    this.hovered = true;
  }

  hideTooltip() {
    this.hovered = false;
  }

}
