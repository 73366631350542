<p-scrollPanel [style]="{ width: '100%', height: '200px' }">
    <div
      class="row mb-1"
      *ngFor="let item of questionInfo?.conversation"
    >
      <div
        class="col-lg-12"
        style="display: flex; flex-direction: row-reverse"
        *ngIf="!item.isLeft"
      >
        <div
          style="
            position: relative;
            bottom: -20%;
            margin-left: 10px;
          "
        >
          <img
            src="./../../../../assets/images/icons/user-avatar.svg"
            alt="user-avatar"
          />
        </div>
        <div
          style="
            background: #7cbbf1;
            color: white;
            padding: 15px;
            border-radius: 15px 15px 1px 15px;
            width: 50%;
            overflow: hidden;
            margin-left: 120px;
          "
        >
          <h6
            style="
              color: white;
              width: 100%;
              text-overflow: ellipsis;
              word-wrap: break-word;
            "
          >
            {{ item?.text }}
          </h6>
        </div>
      </div>
      <div
        class="col-lg-12"
        style="display: flex; flex-direction: row"
        *ngIf="item.isLeft"
      >
        <div
          style="
            position: relative;
            bottom: -20%;
            margin-right: 10px;
          "
        >
          <img
            src="./../../../../assets/images/icons/user-avatar.svg"
            alt="user-avatar"
          />
        </div>
        <div
          style="
            border-radius: 15px 15px 15px 1px;
            padding: 15px;
            width: 50%;
            overflow: hidden;
            margin-right: 120px;
            border: 1px solid black;
          "
        >
          <h6
            style="
              color: black;
              width: 100%;
              text-overflow: ellipsis;
              word-wrap: break-word;
            "
          >
            {{ item?.text }}
          </h6>
        </div>
      </div>
    </div>
  </p-scrollPanel>