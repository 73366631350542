import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
const basePath = environment.basePath;

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  constructor(private http: HttpClient) {}
  getUserState() {
    return this.http.get<any[]>(`${basePath}/users/state`);
  }
}
