import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AnecdoteService } from "app/services/anecdote.service";
import { GlobalService } from "app/services/global.service";
import { el } from "date-fns/locale";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  userState: any;
  levelImg: any;
  constructor(
    private router: Router,
    private anecdoteService: AnecdoteService,
    private globalService: GlobalService
  ) {}

  homeInformations: any;
  todayInfo: any;
  public contentHeader: object;

  ngOnInit() {
    this.homeInformations = {
      sessionCompleted: [
        {
          sessionNumber: 1,
          date: new Date(),
          score: 80.877,
        },
        {
          sessionNumber: 2,
          date: new Date(),
          score: 37.3,
        },
      ],
      trophies: {
        activeTrophe: [],
        notActiveTrophe: [{}, {}, {}, {}],
      },
    };
    this.getRandomAnecdote();
    this.getState();
  }

  getState() {
    this.globalService.getUserState().subscribe((data: any) => {
      this.userState = data;
      this.userState.sessionsHistory = this.userState.sessionsHistory.map(
        (element) => {
          return {
            ...element,
            score: Math.round(element.score * 100) / 100,
          };
        }
      );
      this.levelImg = `./../../.././../assets/images/svg/navbar-stepper/level-${data.level}.svg`;
    });
  }
  getRandomAnecdote() {
    this.anecdoteService.getrandomAnecdote().subscribe((data) => {
      this.todayInfo = data;
    });
  }
  goToLessonPresentation() {
    this.router.navigate(["/lessons/lesson-presentation"]);
  }
  goToEntertainment() {
    this.router.navigate(["/lessons/lesson-training"]);
  }

  goToNextStep() {
    if (this.userState.type == "TEST") {
      this.router.navigate(["/level-test/start"]);
    }
    if (this.userState.type == "SESSION") {
      this.router.navigate(["/lessons/lesson-presentation"]);
    }
  }
}
