<!-- <div
  *ngIf="coreConfig.layout.menu.hidden"
  class="navbar-header d-xl-block d-none"
> -->
<!-- Navbar brand -->
<!-- <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img
            src="{{ coreConfig.app.appLogoImage }}"
            alt="brand-logo"
            width="170"
          />
        </span>
      </a>
    </li>
  </ul> -->
<!--/ Navbar brand -->
<!-- </div> -->

<div class="navbar-container d-flex flex-wrap-reverse pl-lg-5">
  <span class="brand-logo d-flex d-md-none">
    <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="170" />
  </span>
  <div class="d-none d-md-flex">
    <img *ngIf="!!levelImg" [src]="levelImg" alt="step" width="500px" />
  </div>
  <ul
    class="nav navbar-nav align-items-center ml-auto"
    *ngIf="!coreConfig?.layout?.navbar?.profileSection?.hidden"
  >
    <!-- ? Language selection | Uncomment if needed-->
    <!-- <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
        ><span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a
      >
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a>
      </div>
    </li> -->
    <!--/ Language selection -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-notification">
      <a
        class="nav-link dropdown-toggle dropdown-notification-link"
        id="dropdown-notification"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <img src="assets/images/svg/notif.svg" width="30px" height="30px" />
      </a>
      <!-- <div
        ngbDropdownMenu
        aria-labelledby="navbarUserDropdown"
        class="dropdown-menu dropdown-menu-right"
      >
        <a ngbDropdownItem [routerLink]="['/']"
          ><span [data-feather]="'user'" [class]="'mr-50'"></span> Profile</a
        >
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem [routerLink]="['login']"
          ><span
            [data-feather]="'power'"
            [class]="'mr-50'"
            (click)="logout()"
          ></span>
          Se déconnecter</a
        >
      </div> -->
    </li>
    <li ngbDropdown class="nav-item dropdown-user" style="margin-left: 45px">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style="display: flex"
      >
        <p-avatar
          [label]="userPicture ? null : userAvatar"
          [image]="userPicture ? userPicture : null"
          shape="square"
          size="xlarge"
          styleClass="user-avatar"
        ></p-avatar>
        <span style="font-size: 14px; color: black; margin-left: 19px">
          Bonjour, {{ userInfo?.firstName }}!
        </span>
      </a>
      <div
        ngbDropdownMenu
        aria-labelledby="navbarUserDropdown"
        class="dropdown-menu dropdown-menu-right"
      >
        <!-- <a ngbDropdownItem [routerLink]="['/']">
          <span [data-feather]="'user'" [class]="'mr-50'"></span>
          Profile
        </a>
        <div class="dropdown-divider"></div> -->
        <a ngbDropdownItem [routerLink]="['login']" (click)="logout()">
          <span [data-feather]="'power'" [class]="'mr-50'"></span>
          Se déconnecter
        </a>
      </div>
    </li>
    <!--/ User Dropdown -->
    <li class="nav-item bookmark-wrapper d-flex align-items-center">
      <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->

      <ul
        class="nav navbar-nav d-xl-none"
        *ngIf="!coreConfig.layout.menu.hidden"
      >
        <li class="nav-item">
          <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
            <span [data-feather]="'menu'" [class]="'ficon'"></span>
          </a>
        </li>
      </ul>
      <!--/ Menu Toggler -->
    </li>
  </ul>
</div>
